import Vue from "vue"
import VueRouter from "vue-router"
import Redirect from "@/views/Redirect.vue"
import store from "@/store/index"

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: "*",
		component: () => import("../views/404.vue")
	},
	{
		path: "/",
		name: "Home",
		redirect: "/helper"
	},
	{
		path: "/helper",
		name: "Helper",
		layout: "dashboard",
		component: () => import("../views/Helper.vue"),
		meta: { title: "首页" }
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
		meta: { title: "仪表盘" }
	},
	{
		path: "/profile",
		name: "Profile",
		layout: "dashboard",
		component: () => import("../views/Profile.vue"),
		meta: { title: "用户信息", auth: true, layoutClass: "layout-profile" }
	},
	{
		path: "/write",
		name: "Write",
		layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "创作" },
		children: [
			{
				path: "/",
				name: "WriteIndex",
				component: () => import("../views/Write.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "create",
				name: "WriteCreate",
				component: () => import("../views/WriteCreate.vue"),
				meta: { title: "生成", auth: true }
			}
		]
	},
	{
		path: "/role",
		name: "Role",
		layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "角色" },
		children: [
			{
				path: "/",
				name: "RoleIndex",
				layout: "dashboard",
				component: () => import("../views/Role.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "chat",
				name: "RoleChat",
				layout: "dashboard",
				component: () => import("../views/RoleChat.vue"),
				meta: { title: "聊天", auth: true }
			}
		]
	},
	{
		path: "/drawing",
		name: "Drawing",
		component: Redirect,
		redirect: "/",
		meta: { title: "绘画" },
		children: [
			{
				path: "/",
				name: "DrawingIndex",
				layout: "dashboard",
				component: () => import("../views/Drawing.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "canvas",
				name: "DrawingCanvas",
				layout: "default-not",
				component: () => import("../views/DrawingCanvas.vue"),
				meta: { title: "以文生图", auth: true }
			},
			{
				path: "picture",
				name: "DrawingPicture",
				layout: "default-not",
				component: () => import("../views/DrawingPicture.vue"),
				meta: { title: "以图生图", auth: true }
			}
		]
	},
	{
		path: "/chat",
		name: "Chat",
		layout: "dashboard",
		component: () => import("../views/Chat.vue"),
		meta: { title: "AI会话", auth: true }
	},
	{
		path: "/recharge",
		name: "Recharge",
		layout: "dashboard",
		component: () => import("../views/Recharge.vue"),
		meta: { title: "充值中心", auth: true, layoutClass: "layout-recharge" }
	},
	{
		path: "/photo",
		name: "Photo",
		layout: "dashboard",
		component: () => import("../views/DrawingCenter.vue"),
		meta: { title: "作品集", auth: true, layoutClass: "layout-photo" }
	}
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {}
	route.meta.layout = route.layout || parentLayout

	if (route.children) {
		route.children = route.children.map(childRoute => addLayoutToRoute(childRoute, route.meta.layout))
	}
	return route
}

routes = routes.map(route => addLayoutToRoute(route))

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: "smooth"
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.meta.auth === true && !store.state.user.token) {
		store.commit("user/setLoginPopup", true)
	} else {
		next()
	}
})

router.afterEach((to, from) => {})

export default router
